<template>
  <div class="">
    <!-- <div class="card d-flex flex-row mb-3  position-relative h-auto">
            <a class="d-flex card-img" @click.prevent="showDetails" href="#">
                <img :src="batiment.image || `${$router.options.base}img/batiment-default.jpeg`" alt="" style="height: 12rem" class="list-thumbnail responsive border-0">
                <span v-if="isNew" class="badge badge-pill badge-primary position-absolute badge-top-left">{{$t('data.batiment_new')}}</span>
            </a>
            <div class="d-flex flex-grow-1 min-width-zero card-content">
                <div class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                    <div class="text-center">
                        <h4 class="text-red mt-n3 truncate">{{ batiment.nomBatiment }}</h4>
                        <span class="d-inline-block text-muted">{{$t('data.batiment_reference')}}: <b>{{ batiment.refBatiment }}</b></span>
                    </div>
                </div>
                 <div class="list-actions">
                    <a v-if="canSeeDetailsBatiment&&!isSub" href="#" @click.prevent="showDetails" v-b-tooltip.bottom="$t('data.batiment_details')"><i class="ik ik-eye"></i></a>
                    <a v-if="!isSub&&canSeeDetailsBatiment" href="#" @click.prevent="showAnalyses" v-b-tooltip.bottom="$t('data.logement_tooltip_analyses')"><i class="ik ik-activity"></i></a>
                    <a v-if="canSeeDetailsLogement&&isSub" href="#" @click.prevent="accessTo" v-b-tooltip.bottom="$t('data.batiment_acceder_aux_details')"><i class="ik ik-link"></i></a>
                    <a v-if="canUpdateBatiment&&!isSub" href="#" @click.prevent="$emit('makeUpdate', batiment)" v-b-tooltip.bottom="$t('data.batiment_editer')" ><i class="ik ik-edit-2"></i></a>
                    <a v-if="canDeleteBatiment" href="#" @click.prevent="remove" class="list-delete" v-b-tooltip.bottom="$t('data.batiment_supprimer')"><i class="ik ik-trash-2"></i></a>
                </div> 
            </div>
            <div class="date-creation text-muted px-2 pb-n3">
                <p v-if="isSub" class="date w-xs-100 text-small">Crée le : {{ $date(batiment.createdAt).format("DD.MM.YYYY") }}</p>
                <p v-else v-b-tooltip.bottom="dateCreation" class="text-small date w-xs-100">Crée le : {{ $date(batiment.createdAt).format("DD.MM.YYYY") }}</p>
            </div>
            <div v-if="batiment.logements" class="nombre-logement text-white px-2">
                <span class="font-weight-bold h4">{{ batiment.logements.length }}</span>
                <span v-if="batiment.logements.length > 1">
                    {{ $t('data.detail_habitant_logement') }}s
                </span>
                <span v-else>
                    {{ $t('data.detail_habitant_logement') }}
                </span>
            </div>
        </div> -->

    <div class="cite-item">
      <div class="cite-img" @click.prevent="showDetails">
        <img :src="batiment.image" alt="image d'une cité" />
        <div class="nbr-batiment">
          22.01.2023
        </div>
      </div>
      <div class="cite-info">
        <div class="txt">
          <span class="cite-title">
            {{ batiment.nomBatiment }}
          </span>
          <span class="cite-ref">
            Réference : <b>{{ batiment.refBatiment }}</b>
          </span>
        </div>
        <div class="cite-action">
          <span class="possession">
            Possède
            <b>
              {{ batiment.logements.length }}
              <span v-if="batiment.logements.length > 1">
                {{ $t("data.detail_habitant_logement") }}s
              </span>
              <span v-else>
                {{ $t("data.detail_habitant_logement") }}
              </span>
            </b>
          </span>
          <div class="btns">
            <button
              v-if="canSeeDetailsBatiment && !isSub"
              @click.prevent="showDetails"
              v-b-tooltip.bottom="$t('data.batiment_details')"
            >
              <i class="ik ik-eye"></i>
            </button>
            <div class="barre" v-if="canSeeDetailsBatiment && !isSub"></div>
            <button
              v-if="canUpdateBatiment && !isSub"
              @click.prevent="$emit('makeUpdate', batiment)"
              v-b-tooltip.bottom="$t('data.batiment_editer')"
            >
              <i class="ik ik-edit-2"></i>
            </button>
            <div class="barre" v-if="canUpdateBatiment && !isSub"></div>
            <button
              v-if="!isSub && canSeeDetailsBatiment"
              @click.prevent="showAnalyses"
              v-b-tooltip.bottom="$t('data.logement_tooltip_analyses')"
            >
              <i class="ik ik-activity"></i>
            </button>
            <!-- <div class="barre"></div>
                        <button v-if="canSeeDetailsLogement&&isSub" @click.prevent="accessTo" v-b-tooltip.bottom="$t('data.batiment_acceder_aux_details')">
                            <i class="ik ik-link"></i>
                        </button> -->
            <div class="barre" v-if="!isSub && canSeeDetailsBatiment"></div>
            <button
              v-if="canDeleteBatiment"
              @click.prevent="remove"
              v-b-tooltip.bottom="$t('data.batiment_supprimer')"
            >
              <i class="ik ik-trash-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    batiment: { type: Object, required: true },
    isSub: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["hasAccess"]),
    dateCreation() {
      const day = this.$date(this.batiment.createdAt);
      return `Créée le ${day.format("dddd, DD MMMM YYYY")} à ${day.format(
        "HH:mm"
      )}`;
    },
    /**
     * Verifie si c'est une cité nouvellement ajoutée
     */
    isNew() {
      return dayjs().diff(this.batiment.createdAt, "week") <= 1;
    },
    canUpdateBatiment() {
      return this.hasAccess("edit_batiment");
    },
    canDeleteBatiment() {
      return this.hasAccess("delete_batiment");
    },
    canSeeDetailsBatiment() {
      return this.hasAccess("details_batiment");
    },
    canSeeDetailsLogement() {
      return this.hasAccess("details_logement");
    },
  },
  methods: {
    /**
     * Affiche la modale avec les details de l'element
     */
    showDetails() {
      if (this.isSub) {
        return false;
      }
      this.$emit("showDetails", this.batiment);
    },
    /**
     * Affiche la modale avec les details de l'element
     */
    showAnalyses() {
      if (this.isSub) {
        return false;
      }
      this.$emit("showAnalyses", this.batiment);
    },
    /**
     * Acces rapide aux details de l'element
     */
    accessTo() {
      $("#editLayoutItem").modal("hide");
      this.$router.push({
        name: "batiments",
        query: { target: this.batiment.idBatiment },
      });
    },
    /**
     * Suppression du batiment
     */
    remove() {
      App.confirm(
        `Voullez vous vraiment supprimer le batiment " <b>${this.batiment.nomBatiment}</b> " ?`,
        {
          confirm: () => {
            axios
              .delete(`batiments/${this.batiment.idBatiment}`)
              .then((response) => {
                if (!response.success) {
                  return App.notifyError(response.message);
                }
                this.$emit("deleted", this.batiment.idBatiment);
                return App.notifySuccess(response.message);
              })
              .catch((error) => {
                App.notifyError(error.message);
              });
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.date-creation {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 25px;
}
.nombre-logement {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}
.cite-item {
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  width: auto;
  height: 23rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
}
.cite-item .cite-img {
  padding: 5px;
  position: relative;
  width: 100%;
  height: 50%;
  border-radius: 8px 8px 0 0;
}
.cite-img .nbr-batiment {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 130px;
  height: 45px;
  border-radius: 8px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.9rem;
  display: none;
}
.cite-item .cite-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.cite-item .cite-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
  width: 100%;
}
.cite-info .txt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
}
.txt .cite-title {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: lowercase;
}
.txt .cite-title::first-letter {
  text-transform: uppercase;
}
.txt .cite-ref {
  font-size: 0.9rem;
  color: #5c5c5c;
}
.cite-info .cite-action {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.cite-action span {
  font-size: 0.9rem;
  color: #5c5c5c;
}
.cite-action span.possession {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.txt .cite-title::first-letter {
  text-transform: uppercase;
}
.cite-action .btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
}
.cite-action .barre {
  margin: 0 5px;
  height: 80%;
  width: 2px;
  background: #efefef;
}
.cite-action button {
  background: transparent;
  border: none;
}
.cite-action button i {
  font-size: 1rem;
}
</style>
